/* eslint-disable max-len */
class Scroll {
    static scroll(destination, duration = 200) {
        const start = window.scrollY;
        const startTime = "now" in window.performance ? performance.now() : new Date().getTime();
        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName("body")[0].clientHeight;
        const destinationOffset = typeof destination === "number" ? destination : Scroll.getOffsetTop(destination);
        const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset) - 90;
        const easingChoice = Scroll.easing;
        const direction = (start < destinationOffsetToScroll) ? "down" : "up";
        if (!("requestAnimationFrame" in window)) {
            window.scroll(0, (destinationOffsetToScroll > 0) ? destinationOffsetToScroll : 0);
            return;
        }
        function scroll() {
            const now = "now" in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, ((now - startTime) / duration));
            const timeFunction = easingChoice(time);
            window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
            if ((direction === "down" && (window.scrollY >= (destinationOffsetToScroll - 50) || window.scrollY === 0))
                || (direction === "up" && (window.scrollY <= (destinationOffsetToScroll) || window.scrollY === 0))) {
                window.dispatchEvent(new Event("animated.scroll.complete"));
                return;
            }
            requestAnimationFrame(scroll);
        }
        setTimeout(() => {
            scroll();
        }, 1);
    }
}
Scroll.easing = (t) => t * t;
Scroll.getOffsetTop = (element) => {
    const box = element.getBoundingClientRect();
    const docElem = document.documentElement;
    return box.top + window.scrollY - docElem.clientTop;
};
export default Scroll;
