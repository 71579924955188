/* globals jQuery, wordpress_globals */
import "../sass/theme.scss";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
// @ts-ignore
import svgxhr from "webpack-svgstore-plugin/src/helpers/svgxhr";
import "./modules/PageNavigation";
import MicroModal from "micromodal";
const moduleDefinition = [
    { selector: ".react--agent-checker", moduleName: "AgentChecker" },
    { selector: ".swiper.swiper-posts", moduleName: "PostsSwiper" },
    { selector: ".swiper.swiper-vertical-gallery", moduleName: "VerticalGallerySwiper" },
    { selector: ".form.form--coming-soon", moduleName: "ComingSoonForm" },
];
// window.namespace.Contact = Contact;
const bootstrap = {
    init() {
        this.helpers.initSvg();
        this.helpers.setWindowBreakpoints();
        MicroModal.init();
        (moduleDefinition !== null && moduleDefinition !== void 0 ? moduleDefinition : []).forEach(({ selector, moduleName }) => {
            var _a;
            ((_a = document.querySelectorAll(selector)) !== null && _a !== void 0 ? _a : []).forEach((el) => this.modules.loader(el, moduleName));
        });
    },
    modules: {
        loader: (el, moduleName) => {
            try {
                return import(`./modules/${moduleName}`).then((mod) => {
                    const Module = mod.default;
                    return new Module(el);
                });
            }
            catch (error) {
                const err = error;
                // eslint-disable-next-line no-console
                console.warn(err);
            }
            return false;
        },
    },
    helpers: {
        requireAll(r) {
            r.keys().forEach(r);
        },
        initSvg() {
            svgxhr({
                filename: "./wp-content/themes/class/dist/sprite.svg", // URL to fetch
            });
        },
        setWindowBreakpoints() {
            window.breakpoints = {
                "xs": 576,
                "sm": 768,
                "md": 992,
                "lg": 1200,
                "xlg": 1100
            };
        }
    },
};
document.addEventListener("DOMContentLoaded", () => {
    bootstrap.init();
});
// SVG Sprite Loader
bootstrap.helpers.requireAll(require.context("../images/icons/", true, /\.svg$/));
