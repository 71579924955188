/* eslint-disable max-len,no-plusplus,no-param-reassign */
import Scroll from "../lib/Scroll";
class AnimateScroll {
    init() {
        const links = document.querySelectorAll("[data-animated-scroll]");
        if (links.length) {
            links.forEach((link) => {
                link.addEventListener("click", AnimateScroll.initScroll.bind(this));
            });
        }
        window.addEventListener("animate.scroll", (ev) => {
            const element = ev.detail;
            if (typeof element === "undefined" || !element) {
                return false;
            }
            Scroll.scroll(element, 1250);
            return true;
        });
    }
    static initScroll(ev) {
        const targetEl = ev.target;
        let hrefString = targetEl.getAttribute("href");
        if (typeof hrefString === "undefined") {
            console.warn("[AnimateScroll] Href attribute undefined");
            return false;
        }
        if (hrefString === "#" || !hrefString) {
            return false;
        }
        if (hrefString.indexOf("/") === 0) {
            hrefString = hrefString.slice(1);
        }
        const selectedEl = document.querySelector(hrefString);
        if (selectedEl) {
            ev.preventDefault();
            Scroll.scroll(selectedEl, 1250);
            return true;
        }
        console.warn(`[AnimateScroll] Referenced element does not exist - ${targetEl.getAttribute("href")}`);
        return false;
    }
}
export default AnimateScroll;
