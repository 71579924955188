import AnimateScroll from "./AnimatedScroll";
class PageNavigation {
    static initEvents() {
        var _a;
        (document.querySelectorAll(".menu-toggle")).forEach((el) => {
            el.addEventListener("click", PageNavigation.toggleMenu);
        });
        if (document.body.classList.contains("home")) {
            ((_a = document.querySelectorAll("[href^='/#']")) !== null && _a !== void 0 ? _a : []).forEach((link) => {
                link.addEventListener("click", (ev) => {
                    AnimateScroll.initScroll(ev);
                    document.documentElement.classList.remove("menu-open");
                    PageNavigation.changeToggleIcon();
                });
            });
        }
        window.addEventListener("DOMContentLoaded", PageNavigation.moveToHash);
        window.addEventListener("animated.scroll.complete", PageNavigation.clearHash);
    }
    static toggleMenu(ev) {
        ev.preventDefault();
        document.documentElement.classList.toggle("menu-open");
        PageNavigation.changeToggleIcon();
    }
    static moveToHash() {
        var _a;
        if (window.location.hash) {
            window.scroll(0, 0);
            const event = new CustomEvent("animate.scroll", {
                detail: (_a = document.querySelector(window.location.hash)) !== null && _a !== void 0 ? _a : 0,
            });
            window.dispatchEvent(event);
            document.documentElement.classList.remove("menu-open");
            PageNavigation.changeToggleIcon();
        }
    }
    static changeToggleIcon() {
        const isOpen = document.documentElement.classList.contains("menu-open");
        (document.querySelectorAll(".menu-toggle use")).forEach((el) => {
            el.setAttribute("xlink:href", isOpen ? "#menu-close" : "#menu-burger");
        });
    }
    static clearHash() {
        window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
}
PageNavigation.initEvents();
